<template>
  <sm-report :reportName="reportName" :breadcrumbs="breadcrumbs" />
</template>

<script>
import SmReport from '@/components/views/SmReport.vue';
export default {
  name: 'MetersCheckupReport',

  components: {
    SmReport,
  },

  data() {
    return {
      reportName: 'MetersCheckupReport',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Сводный отчет по датам поверки приборов учета',
        },
      ],
    };
  },
};
</script>
